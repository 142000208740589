<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col
        cols="12" sm="7" md="7" lg="7"
        align-self="end" class="pb-1"
      >
        <span class="headline font-weight-bold primary--text" v-text="$route.name"></span>
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
        md="5" lg="5" sm="5"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-text-field
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            outlined
            rounded
            dense
            color="primary"
            placeholder="Buscar categorías"
            v-model="search"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" md="5" lg="5" class="mb-4" :class="{'pr-10':$vuetify.breakpoint.smAndUp, 'pr-3':$vuetify.breakpoint.smAndDown, 'elevation-2':$vuetify.breakpoint.smAndDown }">
        <validation-observer ref="observercategory" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <span class="font-weight-bold">Añadir nueva categoría</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <span>Nombre</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined dense rounded prepend-inner-icon="mdi-playlist-edit" v-model="formCategory.name" :error-messages="errors[0]" hint="El nombre será como aparecerá en su sitio" persistent-hint :disabled="saving_category"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0">
              <span>Descripción</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Descripción" vid="description" rules="required" v-slot="{ errors }">
                 <v-textarea
                    name="Descripcion"
                    auto-grow
                    :error-messages="errors[0]"
                    prepend-inner-icon="mdi-playlist-edit"
                    outlined
                    placeholder=" "
                    v-model="formCategory.description"
                    :disabled="saving_category"
                  ></v-textarea>
              </validation-provider>              
            </v-col>  
            <v-col cols="12" class="py-0 d-flex align-center">
              <v-btn rounded small depressed color="sidebar" style="padding-left: 20px !important; padding-right: 20px !important" @click="validatecategory" :loading="saving_category">
                <span  v-text="action_category + ' categoría'"></span>
              </v-btn>
              <v-spacer/>
              <template v-if="action_category === 'Actualizar'">
                <span class="px-2 font-weight-bold">Traducir:</span>
                <v-btn icon color="sidebar" @click="assignLang('en')">
                  <div style="font-size:18px"><flag iso="us" /></div>
                </v-btn>     
                <v-btn icon color="sidebar" @click="assignLang('fr')">
                  <div style="font-size:18px"><flag iso="fr" /></div>
                </v-btn>  
                <v-btn icon color="sidebar" @click="assignLang('zh')">
                  <div style="font-size:18px"><flag iso="cn" /></div>
                </v-btn>            
              </template>
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
      <v-col cols="12" sm="7" md="7" lg="7">
        <v-card flat class="mt-0">
          <v-sheet class="" v-if="load_category">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>
          <v-data-table
            v-else
            :headers="headers"
            :items="categories"
            class="elevation-0 striped"
            :search="search"
            no-data-text="No se encontraron Usuarios"
            no-results-text="No se encontraron Coincidencias"
            :sort-by.sync="sort_category"
            show-select
            fixed-header
            item-key="id"
            loading-text="Obteniendo Usuarios"
            v-model="selected_dishe"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-row v-if="$vuetify.breakpoint.smAndDown">
                <v-col cols="12" class="pt-0">
                  <v-text-field
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    outlined
                    rounded
                    dense
                    color="primary"
                    placeholder="Buscar categorías"
                    v-model="search"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.smAndUp">
                <v-col cols="12" sm="6" md="4" lg="4" class="d-flex align-center pt-0">
                  <!-- <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        Acciones en lote
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>   
                        <v-list-item>
                          <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>                
                    </v-list>
                  </v-menu>   
                  <v-btn rounded small depressed color="sidebar" class="ml-2" style="padding-left: 10px !important; padding-right: 10px !important">
                    Aplicar
                  </v-btn> -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        <v-icon small>mdi-filter</v-icon>
                        Ordenar
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(filter,i) in filter_table" :key="i"  @click="sort_category = filter.value">
                          <v-list-item-title v-text="filter.text"></v-list-item-title>
                        </v-list-item>                                           
                    </v-list>
                  </v-menu> 
                </v-col>
                <v-col cols="8" class="px-0 pt-0">
                  <v-data-footer 
                    style="border:none !important"
                    :pagination="pagination" 
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>                  
                </v-col>
              </v-row>
            </template>
<!--             <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox color="info" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template> -->
            <template v-slot:header.image>
              <v-icon color="blue-grey lighten-3" dark>mdi-image</v-icon>
            </template>
            <template v-slot:item.image>
              <v-icon color="blue-grey lighten-3" dark>mdi-image-outline</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold">{{ item.name | parseEs }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="actionUpdate(item)" v-if="$can('Editar categoria')">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                 <v-btn icon color="primary lighten-1"  @click="deletecategory(item)" v-if="$can('Borrar categoria')">
                  <v-icon color="primary lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>                  
              </div>
            </template>
          </v-data-table>            
        </v-card>
      </v-col>
    </v-row>
        <!-- *******MODAL DE TRADUCCIONES****** -->
    <base-material-modal v-model="modal_lang" icon="mdi-flag" :loadcard="saving_category" :title="`Añadir Traducción (${lang})`">
      <template v-slot:content>
        <validation-observer ref="category_lang" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <span>Nombre</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined dense rounded prepend-inner-icon="mdi-playlist-edit" v-model="formLang.name" :error-messages="errors[0]" hint="El nombre será como aparecerá en su sitio" persistent-hint :disabled="saving_category"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0">
              <span>Descripción</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Descripción" vid="description" rules="required" v-slot="{ errors }">
                 <v-textarea
                    name="Descripcion"
                    auto-grow
                    :error-messages="errors[0]"
                    prepend-inner-icon="mdi-playlist-edit"
                    outlined
                    placeholder=" "
                    v-model="formLang.description"
                    :disabled="saving_category"
                  ></v-textarea>
              </validation-provider>              
            </v-col>  
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed 
            small
            @click="modal_lang = false"
            :disabled="saving_category"
            rounded
            style="padding-right: 20px;padding-left: 20px;"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_category" @click="validateLang" rounded style="padding-right: 20px;padding-left: 20px;">
            Guardar traducción
          </v-btn>
      </template>   
    </base-material-modal>
    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
    >
      {{ snackbar.text }}
    </base-material-snackbar>    
  </v-container>
</template>

<script>

  export default {
    name: 'categories',
    data () {
      return {
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        filter_status:[],
        search:'',
        selected_dishe: [],
        load_category: false,
        saving_category: false,
        saving_lang: false,
        modal_lang: false,
        categories: [],
        roles:[],
        restaurants: [],
        sort_category: '',
        headers: [
          {
            text: '',
            value: 'image',
            sortable: false,
            width: '60',
            align: 'center',
          },
          { text: 'Nombre', value: 'name', class:"primary--text" },
          { text: 'Cantidad', value: 'cantidad', class:"primary--text" },
          { text: '', value: 'actions' },
        ],
        formCategory:{
          name: '',
          description: '',
          restaurant_id: '',
          lang: 'es',
        },
        formLang:{
          name: '',
          description: '',
          restaurant_id: '',
          lang: '',
        },
        lang_category:{
          name: [],
          description: [],
        },
        id_category: '',
        action_category: 'Añadir nueva',
      }
    },
    created(){
      if(this.$store.getters['auth/isAdmin']){
        this.formCategory.restaurant_id = this.$route.params ? this.$route.params.id : ''; 
      }
      else{
        this.formCategory.restaurant_id =  this.$store.getters['auth/restaurant']; 
      }
      this.getCategories();
    },
    methods: {
      async getCategories(){
        this.load_category= true;
        const id_restaurant = this.$store.getters['auth/isAdmin'] ? this.$route.params.id : this.$store.getters['auth/restaurant']
        const url = `api/categories?restaurant_id=${id_restaurant}`
        try{
          const { data } = await this.$api.get(url);

          this.categories = data.data.categories;            

          this.load_category= false;        
        } 
        catch(error){
          this.load_category= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las Categorías',
            type: 'error',
            active:true,
          };
          
        }
      },
      resetcategory(){
        this.modal_category = false;
        this.id_category = '';
        this.action_category = "Añadir nueva";
        this.formCategory.name = '';
        this.formCategory.description = '';
         
        this.selected_category = [];
        this.$refs.observercategory.reset();
      },
      validatecategory(){
        this.$refs.observercategory.validate().then(result =>{
          if(result){
            this.actionscategory();
          }
        });
      },
      validateLang(){
        this.$refs.category_lang.validate().then(result =>{
          if(result){
            this.actionscategory(true);
          }
        });
      },
      async actionscategory(traslation = null){
        this.saving_category = true;
        const url = this.action_category == 'Añadir nueva' ? 'api/categories' : `api/categories/${this.id_category}`;
        const method = this.action_category == 'Añadir nueva' ? 'post' : 'put';

        try{
          const send_category = await this.$api({
            url: url,
            method: method,
            data: traslation ? this.formLang : this.formCategory
          });        
          this.resetcategory();
          this.getCategories();
          this.saving_category = false;
          this.modal_lang = false;
          this.snackbar = {
            text: send_category.data.message,
            type: 'success',
            active: true            
          }
        }
        catch(e){
          if(e.response.status == "422"){
            this.$refs.observercategory.setErrors(e.response.data.data);            
          }
          else{
            this.snackbar = {
              text:  e.response.data.message,
              type: 'error',
              active: true            
            }            
          }
          this.saving_category = false;
        }
      },
      async actionUpdate(item){
        this.saving_category = true;
        this.id_category = item.id;
        this.action_category = 'Actualizar';
        const url = `api/categories/${this.id_category}/edit`;
        try{
          const { data } = await this.$api.get(url);
          this.lang_category.name = JSON.parse(data.data.category.name);
          this.lang_category.description = JSON.parse(data.data.category.description);
          this.formCategory.name = this.lang_category.name.es;            
          this.formCategory.description = this.lang_category.description.es;            
          this.saving_category = false;
        }
        catch(e){
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true            
          }
          this.saving_category = false;
        }
      },
      async deletecategory(item){
        const categoría = JSON.parse(item.name)
        const msj = "Se eliminaran permanentemente los datos de la Categoría " + categoría.es;

        const confirm = await this.$root.$confirm('¿Estas Seguro de Eliminar?',msj, { type: 'delete', btnConfirmText: 'Si, Eliminalo', btnCancelText: 'Descartar' });

        if(confirm){
          this.load_category= true;
          const url = 'api/categories/' + item.id;
          try{
            const {data} = await this.$api.delete(url);         
            this.load_category= false;
            this.getCategories();
            this.search = '';
            this.snackbar = {
              text: data.message,
              type: 'success',
              active: true            
            }
          }
          catch(error){
            this.load_category= false;
            if(error.response.status == "400"){
              this.snackbar = {
                text: error.response.data.message,
                type: 'error',
                active: true            
              }    
            }
            else{
              this.snackbar = {
                text: 'Lo sentimos, hubo un error al intentar eliminar la Categoría.',
                type: 'error',
                active: true            
              }              
            }
          }
        }
      },
      assignLang(lang){
        this.formLang.name = this.lang_category.name[lang] ? this.lang_category.name[lang] : this.lang_category.name.es;
        this.formLang.description = this.lang_category.description[lang] ? this.lang_category.description[lang] : this.lang_category.description.es;
        this.formLang.lang = lang;
        this.formLang.restaurant_id = this.formCategory.restaurant_id;
        this.modal_lang = true;
      }
    },
    computed:{
      filter_table(){
        return this.headers.filter(item => item.text != '');
      },
      lang(){
        let traslate = '';
        switch(this.formLang.lang){
          case 'en':
            traslate = 'Inglés';
          break;
          case 'fr':
            traslate = 'Francés';
          break;
          case 'zn':
            traslate = 'Chino';
          break;
          default:
            traslate = '';
        }

        return traslate;
      }
    },
    filters:{
      parseEs(value){
        const langs = JSON.parse(value);

        return langs.es;
      }
    }
  }
</script>
<style scope>
  .active-filter{
    font-weight: bold;
    color: #283145 !important;
    background: white !important;
  }
  .active-filter:before {
    background-color: transparent !important;
  }

</style>